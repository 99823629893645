
.icon-minus:before { content: '\e800'; } /* '' */
.icon-plus:before { content: '\e801'; } /* '' */
.icon-user:before { content: '\e802'; } /* '' */
.icon-home:before { content: '\e803'; } /* '' */
.icon-share:before { content: '\e804'; } /* '' */
.icon-envelope:before { content: '\e805'; } /* '' */
.icon-phone:before { content: '\e806'; } /* '' */
.icon-caret-down:before { content: '\e807'; } /* '' */
.icon-caret-up:before { content: '\e808'; } /* '' */
.icon-caret-left:before { content: '\e809'; } /* '' */
.icon-caret-right:before { content: '\e80a'; } /* '' */
.icon-ok:before { content: '\e80b'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-arrow-circle-right:before { content: '\f0a9'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-file-pdf-o:before { content: '\f1c1'; } /* '' */
.icon-paper-plane-o:before { content: '\f1d9'; } /* '' */
