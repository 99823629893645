/* GENERAL			*/
/* SECTIONS			*/
/* XO-LEFT-RIGHT	*/
/* XO-TOP-BOTTOM	*/
/* XO-COLUMNSPACE	*/
/* NAVIGATION		*/
/* HERO				*/
/* HEADLINES		*/
/* PARAGRAPHS		*/
/* BUTTONS			*/
/* CARDS			*/
/* PE-ICONS			*/
/* CAROUSEL			*/
/* TABLE			*/
/* MODAL			*/
/* WELL				*/
/* PANELS			*/
/* CONTACT			*/
/* FOOTER			*/
/* SOCIAL			*/
/* TYPED			*/




/* GENERAL
----------------------------------------------------*/

@import 'bootstrap';

html {
	font-size: 100%;
	font-size: 18px;
	height:100% }

body {
	font-family: "Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
	font-size: 18px;
	line-height: 24px;
	color: #444;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
	height:100% }

.fonts-loaded {
  body {
    font-family: 'Lato',"Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  }
}
		
a {
	color: #444;
	text-decoration: none;
	 -webkit-transition: all 0.15s;
	 -moz-transition: all 0.15s;
	 -ms-transition: all 0.15s;
	 -o-transition: all 0.15s;
	 transition: all 0.15s }

a:hover,
a:focus,
a:active {
	color: #c3112e;
	text-decoration: none }

.craft-wysiwyg a {
	color: #c3112e }

.craft-wysiwyg a:hover,
.craft-wysiwyg a:focus,
.craft-wysiwyg a:active {
	color: #c3112e;
	text-decoration: underline }

a.std {
	color: #ac9fc8 }
	
a.std:hover,
a.std:focus,
a.std:active {
	color: #c3112e }
	
a.highlight {
	color: #c3112e }
	
a.highlight:hover,
a.highlight:focus,
a.highlight:active {
	color: #c3112e;
	text-decoration: underline }

a.callme {
	color: #fff!important }

a.callme:hover,
a.callme:focus,
a.callme:active {
	color: #fff }
	
a.get-red:hover,
a.get-red:focus,
a.get-red:active {
	color: #c3112e; }
	
.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}
	
.noborder {
	border: none!important;
	padding-bottom: 0!important }
	
small {
	font-size: 80%;
	font-weight: inherit!important }
  
strong {
	font-weight: 700 }

em {
	font-style: italic }
	
abbr {
   border-bottom: none!important;
   cursor: help }
   
.fade.in {
	opacity: .95;
	background-color: #ccc }

.img-center {
	margin: 0 auto;
	text-align: center }
	
.img-transforms {
	display: block!important;
	width: 100%!important }

.img-hero {
	display: block!important;
	width: 100%!important;
	max-width: 800px!important}
	
.img-bottomspace {
	margin-bottom: 24px }
		
@media (min-width: 992px) {
	.vertical-align {
	    display: flex;
	    align-items: center } }

@media (max-width: 399px) {
  .hidden-xxs {
    display: none !important;
  }
}
@media (min-width: 400px) and (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (max-width: 399px) {
  .visible-xxs {
    display: block !important;
  } }




/* SECTIONS
----------------------------------------------------*/

div.xo-warning {
	background-color: #000;
	color: #fff }

div.grey-light {
	background-color: #eee;
	color: #444 }

div.grey {
	background-color: #ddd;
	color: #444 }
	
div.grey-dark {
	background-color: #666;
	color: #fff }
	
div.black {
	background-color: #333;
	color: #fff }
	
div.white {
	background-color: #fff;
	color: #444 }

div.orange {
	background-color: #f0ad4e;
	color: #fff }




/* XO-LEFT-RIGHT
----------------------------------------------------*/

.xo-wrapper {
	position: relative;
	margin: 0 2% }
	
	@media (min-width: 600px) {
	.xo-wrapper {
		margin: 0 3.1% } }
		
	@media (min-width: 768px) {
	.xo-wrapper {
		margin: 0 4.2% } }
		
	@media (min-width: 992px) {
	.xo-wrapper {
		margin: 0 5.5% } }
		
	@media (min-width: 1200px) {
	.xo-wrapper {
		margin: 0 6.5% } }
		
	@media (min-width: 1400px) {
	.xo-wrapper {
		margin: 0 auto;
		max-width: 1200px } }
	
.xo-wrapper:after {
	clear: both }




/* XO-TOP-BOTTOM
----------------------------------------------------*/

.xo-top-00 {
	padding-top: 0px }
.xo-top-10 {
	padding-top: 10px }
.xo-top-20 {
	padding-top: 20px }
.xo-top-30 {
	padding-top: 30px }
.xo-top-40 {
	padding-top: 40px }
.xo-top-50 {
	padding-top: 50px }
.xo-top-60 {
	padding-top: 60px }
.xo-top-70 {
	padding-top: 70px }
.xo-top-80 {
	padding-top: 80px }
.xo-top-90 {
	padding-top: 90px }
.xo-top-140 {
	padding-top: 140px }
	
	@media (min-width: 768px) {
		.xo-top-00-sm {
			padding-top: 0px }
		.xo-top-10-sm {
			padding-top: 10px }
		.xo-top-20-sm {
			padding-top: 20px }
		.xo-top-30-sm {
			padding-top: 30px }
		.xo-top-40-sm {
			padding-top: 40px }
		.xo-top-50-sm {
			padding-top: 50px }
		.xo-top-60-sm {
			padding-top: 60px }
		.xo-top-70-sm {
			padding-top: 70px }
		.xo-top-80-sm {
			padding-top: 80px }
		.xo-top-90-sm {
			padding-top: 90px }
		.xo-top-140-sm {
			padding-top: 140px } }
			
	@media (min-width: 992px) {
		.xo-top-00-md {
			padding-top: 0px }
		.xo-top-10-md {
			padding-top: 10px }
		.xo-top-20-md {
			padding-top: 20px }
		.xo-top-30-md {
			padding-top: 30px }
		.xo-top-40-md {
			padding-top: 40px }
		.xo-top-50-md {
			padding-top: 50px }
		.xo-top-60-md {
			padding-top: 60px }
		.xo-top-70-md {
			padding-top: 70px }
		.xo-top-80-md {
			padding-top: 80px }
		.xo-top-90-md {
			padding-top: 90px }
		.xo-top-140-md {
			padding-top: 140px } }

	@media (min-width: 1200px) {
		.xo-top-00-lg {
			padding-top: 0px }
		.xo-top-10-lg {
			padding-top: 10px }
		.xo-top-20-lg {
			padding-top: 20px }
		.xo-top-30-lg {
			padding-top: 30px }
		.xo-top-40-lg {
			padding-top: 40px }
		.xo-top-50-lg {
			padding-top: 50px }
		.xo-top-60-lg {
			padding-top: 60px }
		.xo-top-70-lg {
			padding-top: 70px }
		.xo-top-80-lg {
			padding-top: 80px }
		.xo-top-90-lg {
			padding-top: 90px }
		.xo-top-150-lg {
			padding-top: 150px } }

.xo-bottom-00 {
	padding-bottom: 0px }
.xo-bottom-12 {
	padding-bottom: 12px }
.xo-bottom-20 {
	padding-bottom: 20px }
.xo-bottom-30 {
	padding-bottom: 30px }
.xo-bottom-40 {
	padding-bottom: 40px }
.xo-bottom-50 {
	padding-bottom: 50px }
.xo-bottom-60 {
	padding-bottom: 60px }
.xo-bottom-70 {
	padding-bottom: 70px }
.xo-bottom-80 {
	padding-bottom: 80px }
.xo-bottom-90 {
	padding-bottom: 90px }
	
	@media (min-width: 768px) {
		.xo-bottom-00-sm {
			padding-bottom: 0px }
		.xo-bottom-20-sm {
			padding-bottom: 20px }
		.xo-bottom-30-sm {
			padding-bottom: 30px }
		.xo-bottom-40-sm {
			padding-bottom: 40px }
		.xo-bottom-50-sm {
			padding-bottom: 50px }
		.xo-bottom-60-sm {
			padding-bottom: 60px }
		.xo-bottom-70-sm {
			padding-bottom: 70px }
		.xo-bottom-80-sm {
			padding-bottom: 80px }
		.xo-bottom-90-sm {
			padding-bottom: 90px } }
			
	@media (min-width: 992px) {
		.xo-bottom-00-md {
			padding-bottom: 0px }
		.xo-bottom-20-md {
			padding-bottom: 20px }
		.xo-bottom-30-md {
			padding-bottom: 30px }
		.xo-bottom-40-md {
			padding-bottom: 40px }
		.xo-bottom-50-md {
			padding-bottom: 50px }
		.xo-bottom-60-md {
			padding-bottom: 60px }
		.xo-bottom-70-md {
			padding-bottom: 70px }
		.xo-bottom-80-md {
			padding-bottom: 80px }
		.xo-bottom-90-md {
			padding-bottom: 90px } }

	@media (min-width: 1200px) {
		.xo-bottom-00-lg {
			padding-bottom: 0px }
		.xo-bottom-20-lg {
			padding-bottom: 20px }
		.xo-bottom-30-lg {
			padding-bottom: 30px }
		.xo-bottom-40-lg {
			padding-bottom: 40px }
		.xo-bottom-50-lg {
			padding-bottom: 50px }
		.xo-bottom-60-lg {
			padding-bottom: 60px }
		.xo-bottom-70-lg {
			padding-bottom: 70px }
		.xo-bottom-80-lg {
			padding-bottom: 80px }
		.xo-bottom-90-lg {
			padding-bottom: 90px } }




/* XO-COLUMNSPACE
----------------------------------------------------*/

@media (min-width: 768px) {
.morecolumnspace {
	margin-right: -30px } }

@media (min-width: 768px) {
.moregutterright {
	padding-right: 30px } }
	
@media (min-width: 992px) {
.muchmorecolumnspace {
	margin-right: -70px } }
	
@media (min-width: 992px) {
.muchmoregutterright {
	padding-right: 70px } }
	
@media (min-width: 1200px) {
.muchmorecolumnspace {
	margin-right: -90px } }
	
@media (min-width: 1200px) {
.muchmoregutterright {
	padding-right: 90px } }




/* NAVIGATION
----------------------------------------------------*/

.navbar {
	padding-left: 0!important;
	padding-right: 0!important }

.xo-navbar {
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 14px; } 

@media (min-width: 600px) {
	.xo-navbar {
    	padding-left: 3.1%;
		padding-right: 3.1% } }

@media (min-width: 768px) {
	.xo-navbar {    	
    	padding-left: 4.2%;
		padding-right: 4.2% } }
		
@media (min-width: 992px) {
	.xo-navbar {
    	padding-left: 5.5%;
		padding-right: 5.5% } }
		
@media (min-width: 1200px) {
	.xo-navbar {
		padding-bottom: 20px;
		padding-left: 6.5%;
		padding-right: 6.5% } }

@media (min-width: 1400px) {
	.xo-navbar {
		padding-left: 0;
		padding-right: 0;
		margin: 0 auto;		
		max-width: 1200px } }

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0 }
	
.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto }
	
.collapse {
	display: none }
	
.collapse.in {
  display: block }
  
.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	-webkit-transition-timing-function: ease;
    	-o-transition-timing-function: ease;
        transition-timing-function: ease;
	-webkit-transition-duration: .35s;
    	-o-transition-duration: .35s;
        transition-duration: .35s;
	-webkit-transition-property: height, visibility;
    	-o-transition-property: height, visibility;
        transition-property: height, visibility }
        
.caret {
	display: inline-block;
	width: 0;
	height: 0;
	margin-left: 2px;
	vertical-align: middle;
	border-top: 4px dashed;
	border-top: 4px solid \9;
	border-right: 4px solid transparent;
	border-left: 4px solid transparent }
	
.dropup,
.dropdown {
	position: relative }
	
.dropdown-toggle:focus {
	outline: 0 }
	
.dropdown-menu {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	min-width: 160px;
	padding: 5px 0 9px;
	margin: 2px 0 0;
	font-size: 98%;
	text-align: left;
	list-style: none;
	background-color: #fff;
	-webkit-background-clip: padding-box;
    background-clip: padding-box;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, .15);
	border-radius: 4px;
	-webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175) }
    
.dropdown-menu.pull-right {
	right: 0;
	left: auto }
	
.dropdown-menu .divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5 }
	
.dropdown-menu > li > a {
	display: block;
	padding: 3px 20px;
	clear: both;
	font-weight: 400;
	line-height: 1.42857143;
	color: #444;
	white-space: nowrap }
	
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	color: #262626;
	text-decoration: none;
	background-color: #f5f5f5 }
	
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
	color: #fff;
	text-decoration: none;
	background-color: #c3112e;
	outline: 0 }
	
.open > .dropdown-menu {
	display: block }
	
.open > a {
	outline: 0 }
	
.dropdown-menu-right {
	right: 0;
	left: auto }
	
.dropdown-menu-left {
	right: auto;
	left: 0 }
	
.dropdown-header {
	display: block;
	padding: 3px 20px;
	font-size: 14px;
	font-weight: bold;
	line-height: 1.42857143;
	color: #666;
	white-space: nowrap }
	
.dropdown-backdrop {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 990 }
	
.pull-right > .dropdown-menu {
	right: 0;
	left: auto }
	
@media (min-width: 1200px) {
	.navbar-right .dropdown-menu {
		right: 0;
		left: auto }
  	.navbar-right .dropdown-menu-left {
    	right: auto;
		left: 0 } }

.nav {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	padding-bottom: 0 }
	
.nav > li {
	position: relative;
	display: block;
	margin-left: 0 }
	
@media (min-width: 1200px) {
	.nav > li {
		margin-left: 35px } }
		
.nav > li.first {
	margin-left: 0px; }
	
@media (max-width: 1199px) {
	.nav > li.first {
		margin-top: -5px } }
		
.nav > li > a {
	position: relative;
	display: block;
	padding: 0 }
	
.nav > li > a:hover,
.nav > li > a:focus {
	text-decoration: none;
	background-color: #eee }
	
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	background-color: #eee;
	border-color: transparent }
	
.nav .nav-divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5 }
	
.nav > li > a > img {
	max-width: none }
	
@media (min-width: 1200px) {
	.navbar-header {
    	float: left } }
    	
.navbar-collapse {
	padding-top: 8px;
	margin-top: 14px;
	overflow-x: visible;
	-webkit-overflow-scrolling: touch;
	border-top: 1px solid transparent;
	-webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
	margin-left: 0!important;
	margin-right: 0!important }
	
.navbar-collapse .divider {
	height: 1px;
	margin: 9px 0;
	overflow: hidden;
	background-color: #e5e5e5 }
	
.navbar-collapse.in {
	overflow-y: auto }
	
@media (min-width: 1200px) {
	.navbar-collapse {
		padding-top: 26px;
		margin-top: 0;
		width: auto;
		border-top: 0;
		-webkit-box-shadow: none;
            	box-shadow: none }
  	.navbar-collapse.collapse {
    	display: block !important;
		height: auto !important;
		padding-bottom: 0;
		overflow: visible !important }
  	.navbar-collapse.in {
  		overflow-y: visible }
  	.navbar-fixed-top .navbar-collapse,
  	.navbar-static-top .navbar-collapse {
    	padding-right: 0;
		padding-left: 0 } }
		
.navbar-fixed-top .navbar-collapse {
	max-height: 380px }
	
@media (max-device-width: 480px) and (orientation: landscape) {
	.navbar-fixed-top .navbar-collapse {
    	max-height: 200px } }
    	
.navbar-static-top {
	z-index: 1000;
	border-width: 0 0 1px }
	
@media (min-width: 1200px) {
	.navbar-static-top {
    	border-radius: 0 } }
    	
.navbar-fixed-top {
	z-index: 1030 }
	
@media (min-width: 1200px) {
	.navbar-fixed-top{
    	border-radius: 0 } }
    	
.navbar-fixed-top {
	top: 0;
	border: none }
	
.navbar-brand {
	float: left;
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: 22px;
	line-height: 20px;
	height: 52px }
	
@media (min-width: 1200px) {
	.navbar-brand {
		padding-top: 15px;
		padding-bottom: 15px;
		padding-left: 0;
		height: 66px } }
		
.navbar-brand:hover,
.navbar-brand:focus {
	text-decoration: none }
	
.navbar-brand > img {
	display: block;
	height: 52px }
	
@media (min-width: 1200px) {
	.navbar-brand > img {
		height: 66px } }
		
.navbar-brand > svg {
	height: 52px;
	width: 180px }
	
@media (min-width: 1200px) {
	.navbar-brand > svg {
		height: 66px;
		width: 229px } }
		
.navbar-toggle {
	position: relative;
	float: right;
	padding: 12px 0px;
	margin-top: 19px;
	margin-bottom: 7px;
	background-color: transparent;
	background-image: none;
	border: none;
	border-radius: 4px }
	
.navbar-toggle:focus {
	outline: 0 }
  
.navbar-toggle .icon-bar {
	display: block;
	width: 22px;
	height: 3px;
	border-radius: 1px }
  
.navbar-toggle .icon-bar + .icon-bar {
	margin-top: 4px }
	
@media (min-width: 1200px) {
	.navbar-toggle {
    	display: none } }
    	
.navbar-nav {
	margin-bottom: 0;
	font-size: .805em;
	font-weight: 400;
	letter-spacing: .8px;
	text-transform: uppercase }
  	
.navbar-nav > li > a {
	padding-top: 10px;
	padding-bottom: 10px;
	line-height: 20px }
	
@media (max-width: 1199px) {
	.navbar-nav .open .dropdown-menu {
	    position: static;
	    float: none;
	    width: auto;
	    margin-top: 9px;
	    padding-top: 10px;
	    padding-bottom: 0px;
	    background-color: transparent;
	    border: 0;
	    border-top: 1px solid #e5e5e5;
	    border-radius: 0;
	    -webkit-box-shadow: none;
	            box-shadow: none }
	.navbar-nav .open .dropdown-menu > li > a,
	.navbar-nav .open .dropdown-menu .dropdown-header {
    	padding: 5px 15px 5px 25px }
	.navbar-nav .open .dropdown-menu > li > a {
    	line-height: 20px }
	.navbar-nav .open .dropdown-menu > li > a:hover,
	.navbar-nav .open .dropdown-menu > li > a:focus {
    	background-image: none } }
    	
@media (min-width: 1200px) {
	.navbar-nav {
		float: left;
		margin: 1px 0 3px 0 }
	.navbar-nav > li {
    	float: left }
	.navbar-nav > li > a {
    	padding-top: 10px;
		padding-bottom: 10px } }
		
.navbar-nav > li > .dropdown-menu {
	margin-top: 0;
	padding-top: 10px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px }
	
.navbar-text {
	margin-top: 15px;
	margin-bottom: 15px }
	
@media (min-width: 1200px) {
	.navbar-text {
    	float: left;
		margin-right: 15px;
		margin-left: 15px } }
		
@media (min-width: 1200px) {
	.navbar-left {
    	float: left !important }
	.navbar-right {
    	float: right !important;
		margin-right: 0 }
	.navbar-right ~ .navbar-right {
    	margin-right: 0 } }
    	
.navbar-default {
	background-color: #fff;
	-webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    border-bottom: 1px solid #ccc;
}
	
.navbar-default .navbar-brand {
	color: #1a1a1a }
	
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
	color: #999;
	background-color: transparent }
	
.navbar-default .navbar-text {
	color: #1a1a1a }
	
.navbar-default .navbar-nav > li > a {
	color: #1a1a1a }
	
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
	color: #999;
	background-color: transparent }
	
.navbar-default .navbar-nav > .active > a {
	color: #c3112e;
	background-color: transparent }
	
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
	color: #999;
	background-color: transparent }

@media (min-width: 1200px) {
	
	.navbar-default .navbar-nav > li > a {
		color: #1a1a1a;
		border-top: 2px solid transparent;
		border-bottom: 2px solid transparent }
		
	.navbar-default .navbar-nav > li > a:hover,
	.navbar-default .navbar-nav > li > a:focus {
		color: #999;
		background-color: transparent;
		border-bottom: 2px solid #fff }
		
	.navbar-default .navbar-nav > .active > a {
		color: #c3112e;
		background-color: transparent;
		border-top: 2px solid #fff }
		
	.navbar-default .navbar-nav > .active > a:hover,
	.navbar-default .navbar-nav > .active > a:focus {
		color: #999;
		background-color: transparent;
		border-bottom: 2px solid #fff }
	}
	
.navbar-default .navbar-toggle {
	border-color: #fff }
	
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
	background-color: transparent }
	
.navbar-default .navbar-toggle .icon-bar {
	background-color: #1a1a1a }
	
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
	border-color: #e7e7e7 }
	
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
	color: #999;
	background-color: transparent }
	
@media (max-width: 1199px) {
	.navbar-default .navbar-nav .open .dropdown-menu > li > a {
    	color: #1a1a1a }
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
	.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    	color: #999;
		background-color: transparent }
	.navbar-default .navbar-nav .open .dropdown-menu > .active > a,
	.navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
	.navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    	color: #c3112e;
		background-color: #fff } }
		
.navbar-default .navbar-link {
	color: #1a1a1a }
	
.navbar-default .navbar-link:hover {
	color: #999 }
  
.navbar-default .btn-link {
	color: #1a1a1a }
  
.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus {
	color: #999 }
	
.navbar-transparent {
	background-color: transparent
}

.nav .dropdown-menu {
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    margin: 0;
    border: none;
    text-transform: none;
    min-width: 200px;
    background: transparent }

.nav .dropdown-menu a {
    padding: 8px 20px;
    color: #1a1a1a;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    text-transform: none }

.nav .dropdown-menu a:hover {
    background: #999;
    color: #fff }


.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
    background: none }


@media only screen and (min-width : 1200px) {
    .nav .dropdown-menu {
    	-webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        -ms-border-radius: 4px;
        -o-border-radius: 4px;
        border-radius: 4px;
        -moz-background-clip: padding;
        -webkit-background-clip: padding-box;
        background-clip: padding-box;
        margin: 0;
        border: none;
        text-transform: none;
        min-width: 180px;
        background: #999 }
    .nav .dropdown-menu:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #999;
        position: absolute;
        left: 25px;
        top: -10px }
    .nav .dropdown-menu a {
        padding: 8px 20px;
        color: #fff;
        -webkit-opacity: 1;
        -moz-opacity: 1;
        opacity: 1;
        font-size: 92%; }
    .nav .dropdown-menu a:hover {
        background: #c3112e;
        color: #fff } }


@media (max-device-width: 480px) and (orientation: landscape) {
    .navbar-fixed-top .navbar-collapse {
        max-height: 200px } }

.affix {
    top: 0;
    width: 100%;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out }

.affix.navbar{
	background-color: #fff;
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1 }
        
.navbar-default.notrans{
	background-color: #fff!important;
	-webkit-opacity: 1!important;
	-moz-opacity: 1!important;
	opacity: 1!important }

.navbar-btn {
    margin-bottom: 5px;
}

@media (min-width: 1199px) {
	.navbar-btn {
	    margin-bottom: 1px;
	}
}
        


/* HERO
----------------------------------------------------*/

.xo-hero-background {
	background-color: #777777;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='49' viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23909090' fill-opacity='0.4' fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
	background-repeat:repeat;
    /* custom background-position */
    background-position:77% 50%;
    /* ie8- graceful degradation */
    background-position:50% 50%9 !important }
    @media (min-width: 768px) {
	   .xo-hero-background { 
		   background-position:50% 50% } }
    @media (min-width: 1200px) {
	   .xo-hero-background { 
		   background-position:50% 50% } }

.xo-hero-overlay {
	position: relative;
    display: block;
    width: 100%;
    height: 100%;
    top: 0; 
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
}
		   
.xo-hero-fullscreen,
.xo-hero-cta {
    width:100%;
    height:100%;
    overflow:hidden }
    
.xo-hero-fullscreen.overflow,
.xo-hero-fullscreen.overflow .xo-hero-cta {
    height:auto;
    min-height: calc(100% - 0); }
    
.xo-hero-halfscreen {
    width:100%;
    height:100%;
    overflow:hidden }
    @media (min-height: 400px) {
	    .xo-hero-halfscreen {
		    height: 55% } }

.xo-hero-halfscreen.overflow,
.xo-hero-halfscreen.overflow .xo-hero-cta {
    height:auto;
    min-height:100% }
    @media (min-height: 400px) {
	    .xo-hero-halfscreen.overflow,
		.xo-hero-halfscreen.overflow .xo-hero-cta {
			height: 55% } }
    
.xo-hero-cta {
	margin-top: 40px;
	margin-bottom: 0px;
    display:table;
    padding-left: 15px;
    padding-right: 15px;
    height: 100% }
    @media (min-width: 768px) {
	    .xo-hero-cta {
		    margin-top: 50px;
		    margin-bottom: 0px;
		    width: 100% } }
		    
.xo-hero-cta-content {
    display:table-cell;
    position:relative;
    vertical-align:middle;
    text-align:center }
    
.xo-hero-cta-content h1,
.xo-hero-cta-content p,
.xo-hero-cta-content btn {
	margin: 6px auto!important;
	padding: 6px 0!important;
	color: #fff }

.xo-hero-cta-content h1 {
	font-size: 30px!important;
	font-weight: 400;
	letter-spacing: .25px;
	text-transform: uppercase; } 
	
	@media (min-width: 768px) {	
		.xo-hero-cta-content h1 {
			font-size: 34px!important;
			letter-spacing: 1px } }
			
.xo-hero-cta-content p {
    color: #fff;
    font-weight: 300;
    font-size: 120%;
    margin-top: 0;
    margin-bottom: 12px;
    padding-top: 12px;
    padding-bottom: 0px;
    line-height: 1.5em } 
    @media (min-width: 768px) {
    	.xo-hero-cta-content p {
        	font-size: 130%;
			font-weight: 300 } }
    @media (min-width: 992px) {
    	.xo-hero-cta-content p {
        	width: 100%;
			font-weight: 300;
			line-height: 1.5em } }
    @media (min-width: 1200px) {
    	.xo-hero-cta-content p {
        	width: 100%;
			font-weight: 300;
			line-height: 1.6em } }
			
.xo-hero-cta-content p.subtext {
	font-size: 115%;
	font-weight: 300;
	line-height: 1.4em; }
	@media (min-width: 768px) {
		.xo-hero-cta-content p.subtext {
			line-height: 1.5em; } }
	@media (min-width: 1200px) {
		.xo-hero-cta-content p.subtext {
			line-height: 1.6em; } }

.xo-hero-cta-image-container {
	margin-top: 0px;
    display:table;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
	text-align: center }
    @media (min-width: 768px) {
	    .xo-hero-cta-image-container {
		    margin-top: 0px } }
	
.xo-hero-cta-image {
    display:table-cell;
    position:relative;
    vertical-align:bottom;
    text-align:center }
    
.xo-scroll-down {
    margin-top: -80px;
    height: 80px;
    display: block;
    width: 100%;
    text-align: center }

.anchor-ghost {
	position: absolute;
	top: -79px }
	@media (min-width: 1200px) {
		.anchor-ghost {
			top: -91px } }

.alert-danger {
	color: #fff;
	background-color: #333;
	background-image: linear-gradient(45deg, #333 25%, #c3112e 25%, #c3112e 50%, #333 50%, #333 75%, #c3112e 75%, #c3112e);
	border-color: #c3112e;
	font-weight: 300;
	padding-top: 18px;
	padding-bottom: 18px;
	margin-top: 18px;
	margin-bottom: 36px }
	@media (min-width: 768px) {
		.alert-danger {
			padding-top: 24px;
			padding-bottom: 24px } }



/* HEADLINES
----------------------------------------------------*/

      
h1, h2, h3, h4, h5, h6 {
	color: #444;
	font-weight: 400;
	line-height: 1.2em;
	margin: 0;
	text-align: center;
	text-transform: none; }  

h1 {
	font-size: 28px;
	font-style: normal;
	padding-bottom: 36px;
	text-transform: uppercase; }
	
	@media (min-width: 768px) {
		h1 {
			font-size: 30px } }
	
	h1.border {
		border-bottom: 1px solid #ccc;
		margin-bottom: 24px;
		padding-bottom: 24px }
	
	h1.white,
	h1.white > small,
	h1.white > span > small {
		color: #fff }
		
	h1.hero {
		padding-bottom: 0!important;
		margin-bottom: 0!important;
	}
			
  
h2 {
	font-size: 26px;
	font-style: normal;
	padding-bottom: 36px; }
	
	@media (min-width: 768px) {
		h2 {
			font-size: 30px } }
	
	h2.border {
		border-bottom: 1px solid #ccc;
		margin-bottom: 24px;
		padding-bottom: 24px }
	
	.craft-wysiwyg > h2 {
		margin-top: 30px;
		border-bottom: 1px solid #ccc;
		margin-bottom: 24px;
		padding-bottom: 24px }
		
	@media (min-width: 768px) {
		.craft-wysiwyg > h2 {
			margin-top: 38px; } }
	
	h2.white,
	h2.white > small {
		color: #fff }
		
	h2.white.border {
		border-color: #fff }
		
	h2.border.impressum {
		text-align: left;
		margin-top: 60px;
		font-size: 24px }
		
		@media (min-width: 768px) {
		h2.border.impressum {
			font-size: 24px } }
			
	h2.get-small {
		font-size: 24px	}
		
		@media (min-width: 480px) {
			h2.get-small {
				font-size: 30px	} }
			
h3 {
	font-size: 24px;
	font-style: normal;
	padding-bottom: 24px; }
	
	h3.white {
		color: #fff }

h4 {
	font-size: 22px;
	font-style: normal;
	padding-bottom: 24px; }
	
	h4.white {
		color: #fff }

.vertical-aligned h1,
.vertical-aligned h2,
.vertical-aligned h3 {
	margin-top: 24px;
	margin-bottom: 24px;
	padding-top: 0;
	padding-bottom: 0 }

hr {
	border-top: 1px solid #ccc }
	
div.purple-light hr{
	border-top: 1px solid #fff }


/* PARAGRAPHS
----------------------------------------------------*/

p {
	color: #333;
	margin: 0 0 24px;
	line-height: 1.8em;
	font-weight: 300;
	font-size: 100% }
	
div.purple-light p {
	color: #fff }

p.breadcrumbs {
	color: #fff;
	font-size: 105%;
	font-weight: 400;
	margin-top: 0!important;
	padding-top: 0!important; }
	
	@media (min-width: 768px) {
		p.breadcrumbs {
			font-weight: 300;
		}
	}

p.breadcrumbs a {
	color: #fff; }
	
p.breadcrumbs a:hover,
p.breadcrumbs a:focus,
p.breadcrumbs a:active {
	color: #fff; }

p.entry-date {
	font-size: 96%;
	margin-top: 12px;
	margin-bottom: 36px;
	text-align: center; }

p.white {
	color: #fff }
	
p.price {
	font-size: 135%;
	font-weight: 300;
	margin: 12px 0 }
	
	@media (min-width: 768px) {
		p.price {
			font-size: 145% } }

.pricehighlight {
    color: #c3112e;
    font-size: 1.3em;
    font-weight: 300;
    letter-spacing: 1px;
}
		
p.cta,
p.nextpage {
	font-size: 160%;
	font-weight: 300;
	color: #444;
	padding: 0;
	margin: 18px 0;
	line-height: 1.3em; }
	
p.teaser {
	font-size: 135% }
	
p.teaser-small {
	margin-top: 24px }
	
p.teaser-more-text {
	font-size: 130%;
	margin-top: 12px }

	@media (min-width: 992px) {
		p.teaser-more-text {
			margin-top: 24px } }

p.smaller {
	font-size: 95% }

p.privacyHint {
	font-size: 85%;
	font-weight: 300;
	margin-left: 3px;
	margin-right: 3px;
	margin-bottom: 0;
	line-height: 1.6em; }

p.contact-get-small {
	font-size: 130%;
	margin-top: 6px }
	@media (min-width: 768px) {
		p.contact-get-small {
			font-size: 160% } }

p.contact-get-smaller {
	font-size: 95% }
	@media (min-width: 768px) {
		p.contact-get-smaller {
			font-size: 120% } }

p.contact-icon {
	margin-top: 18px;
	margin-bottom: 0;
}

p.contact-icon i {
	font-size: 34px;
}
@media (min-width: 768px) {
		p.contact-icon i {
			font-size: 36px } }
			
p.route {
	color: #fff;
	text-align: center;
	margin-top: 24px;
	font-size: 110%; }
	
	p.route i {
		font-size: 170%; }

.margin-b-12 {
	margin-bottom: 12px!important; }

.margin-3-3,
p.margin-3-3 {
	margin-left: 3px;
	margin-right: 3px }

.craft-wysiwyg.margin-5-5 > p,
p.margin-5-5 {
	margin-left: 5px;
	margin-right: 5px }

p.trainerbio {
	margin-bottom: 12px;
	text-align: justify; }

.text-justify {
	text-align: justify; }
	
p.xo-brand {
	font-family: 'Lato',"Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
	font-size: 17px;
	letter-spacing: 1px;
	font-weight: 400 }

p.xo-brand em {
	font-style: normal;
	font-weight: 400 }
	
p.xo-brand.sub {
	line-height: 1em }
	
cite {
	font-style: normal;
	font-size: 135%;
	font-weight: 300;
	font-family: 'Lato',"Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
	color: #444;
	line-height: 1.5em }
	
	@media (min-width: 768px) {
		cite {
			font-size: 150% } }
			
cite > small {
	color: #777 }

.date-passed {
	color: #aeaeae;
}

.sm-center-md-left {
	text-align: center }
	@media (min-width: 992px) {
		.sm-center-md-left {
			text-align: left } }
			
.sm-center-md-right {
	text-align: center }
	@media (min-width: 992px) {
		.sm-center-md-right {
			text-align: right } }




/* BUTTONS
----------------------------------------------------*/
   
.btn {
	display: inline-block;
	padding: 12px 22px;
	margin-top: 20px;
	margin-bottom: 20px;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 400;
	letter-spacing: 1px;
	line-height: 1.85em;
	color: #555;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	background-color: white;
	border: 1px solid #555;
	border-radius: 4px }

.btn:hover,
.btn:focus,
.btn:active {
    color: #c3112e;
    background-color: #fff;
  	border: 1px solid #c3112e;
    text-decoration: none }     

.btn-intro {
	background-color: white;
    border-color: white;
    color: #555 }

.btn-intro:hover,
.btn-intro:focus, 
.btn-intro:active {
	background-color: #c3112e;
	border-color: #c3112e;
	color: white }
	
.btn-highlight {
	background-color: #c3112e;
	border-color: #c3112e;
	color: #fff }

.btn-highlight:hover,
.btn-highlight:focus, 
.btn-highlight:active {
    background-color: #89ba16;
    border-color: #89ba16;
    color: #fff }
    
.btn-highlight-scondary {
	background-color: #fff;
	border-color: #fff;
	color: #c3112e }

.btn-highlight-scondary:hover,
.btn-highlight-scondary:focus, 
.btn-highlight-scondary:active {
    background-color: #89ba16;
    border-color: #89ba16;
    color: #fff }
    
.btn-highlight-disabled {
	background-color: #fff;
	border-color: #fff;
	color: #555 }

.btn-highlight-disabled:hover,
.btn-highlight-disabled:focus, 
.btn-highlight-disabled:active {
    background-color: #fff;
    border-color: #fff;
    color: #c3112e }
    
.btn-universal {
	background-color: white;
	border-color: #555;
	color: #555 }

.btn-universal:hover,
.btn-universal:focus, 
.btn-universal:active {
    background-color: white;
    border-color: #c3112e;
    color: #c3112e }
    
.btn-cycle {
	background-color: inherit;
	border-color: #fff;
	color: #fff }

.btn-cycle:hover,
.btn-cycle:focus, 
.btn-cycle:active {
    background-color: #c3112e;
    border-color: #c3112e;
    color: #fff }
    
.btn-filter {
	background-color: transparent;
	border:0;
	color: #d3d3d3;
	font-weight: 400;
	-moz-osx-font-smoothing:grayscale;
	-webkit-font-smoothing:antialiased;
	font-size: 100%;
	padding:0px 16px;
	margin-top: 0;
	position:relative;
	top:50%;
	-webkit-transform:translateY(-50%);
	-ms-transform:translateY(-50%);
	transform:translateY(-50%) }

.btn-filter:hover,
.btn-filter:focus, 
.btn-filter:active {
    background-color: transparent;
    border:0;
    color: #fff }

.btn-navbar {
	font-size: 88%;
	margin-top: 0px;
	margin-right: 0;
	background-color: #fff;
    border-color: #1a1a1a;
    color: #1a1a1a;
	font-weight: 400;
}

.btn-navbar:hover,
.btn-navbar:focus, 
.btn-navbar:active {
	background-color: #fff;
	border-color: #c3112e;
	color: #c3112e }

@media (max-width: 1199px) {
	.btn-navbar {
		border-radius: 0;
		font-size: 94%;
		margin-top: 10px;
		margin-bottom: 0;
		padding-top: 18px;
		padding-bottom: 17px;
	}
}

@media (min-width: 1200px) {
	.btn-navbar {
		background-color: transparent;
		border-color: #1a1a1a;
		color: #1a1a1a;
		margin-top: -11px;
		margin-bottom: 0;
		padding-bottom: 10px;
	}
	.btn-navbar:hover,
	.btn-navbar:focus, 
	.btn-navbar:active {
		background-color: #fff;
		border-color: #c3112e;
		color: #c3112e;
	}
}

    
.btn-cta {
	font-size: 15px;
	margin: 10px 0 }
	
.btn-contacterror {
	margin-top: 24px }

.btn-double {
	margin-left: 20px;
	margin-right: 20px; }

.btn-left {
	margin-left: 0;
	margin-right: 10px; }

.btn-right {
	margin-left: 10px;
	margin-right:0; }
	
.btn-mr {
	margin-right: 16px; }

.btn-big-icon i {
	font-size: 140%; }

.btn-w-icon {
	padding-right: 12px }

.btn-w-caret-left {
	padding-left: 10px }
	
.btn-w-caret-right {
	padding-right: 10px }


@media (max-width: 767px) and (orientation: portrait) {
	.btn-xs-full-width {
		margin-left: 0;
		margin-right: 0;
		width: 100% } }


@media (max-width: 1199px) {
	.btn-navbar-full {
		margin-left: 0;
		margin-right: 0;
		width: 100% } }






/* PRIVACY
----------------------------------------------------*/

.privacy-and-terms {

}

.privacy-and-terms h2 {
	border: none;
	font-size: 1.4em;
	margin-top: 2.5em;
	padding-top: 0;
	margin-bottom: 1.5em;
	padding-bottom: 0;
	text-align: left;
}

@media (min-width: 992px) {
	.privacy-and-terms h2 {
		font-size: 1.6em;
	}
}

.privacy-and-terms h2:first-of-type {
	margin-top: 2.5em;
}

.privacy-and-terms h3 {
	border: none;
	font-size: 1.3em;
	margin-top: 2em;
	padding-top: 0;
	margin-bottom: 1em;
	padding-bottom: 0;
	text-align: left;
}

@media (min-width: 992px) {
	.privacy-and-terms h3 {
		font-size: 1.4em;
	}
}

.privacy-and-terms ul {
	margin-bottom: 24px;	
}

.privacy-and-terms ul li {
	font-weight: 100;
	line-height: 1.8em;	
}

.privacy-and-terms p small {
	display: block;
	margin-top: 3em;
}







/* CARDS
----------------------------------------------------*/

.card {
	border-radius: 4px;
	box-shadow: 0 1px 4px rgba(0,0,0,.23);
	background-color: #fff;
	margin-bottom: 36px;
	margin-top: 0px }

	
	.card .image {
		width: 100%;
		overflow: hidden;
		height: auto;
		border-radius: 4px 4px 0 0;
		position: relative;
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d }
	
		.card .image img {
			width: auto }

		
	.card .video {
		width: 100%;
		overflow: hidden;
		border-radius: 4px;
		-webkit-border-radius: 4px;
		position: relative;
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d }
	
		.card .video video {
			width: 100% }

	
	.card .content {
		margin-top: 1em;
		min-height:80px;
		padding: 15px 30px 10px;
		text-align:left }
				
		.card h2,
		.card h3,
		.card h4 {
			font-size: 20px;
			margin: 0 0 12px;
			padding-bottom: 12px;
			text-align: left }
			
		.card h2 small,
		.card h3 small,
		.card h4 small {
			font-size: 75%; }
			
		.card p.subtitle {
			font-size: 15px;
			margin: -22px 0 24px;
			padding-top: -12px }
			
		.card p.description,
		div.description p {
			font-size: 17px }
		
		.card .footer {
			background-color: transparent;
			line-height: 30px;
			margin-top: -10px;
			padding: 0px 30px 30px }
			
		.card .footer .btn {
			font-size: 13px;
			margin-top: 10px }
			
			@media (min-width:768px) {
				.card .footer .btn {
					font-size: 14px } }
			
		.card .footer .btn-classicLink {
			font-size: 15px;
			margin-bottom: 8px;
			margin-top: -10px }
			
		.card .footer .btn-classicLink i {
			margin-right: 7px }
	
	
	.card-center .content {
		text-align:center }
				
		.card-center h2,
		.card-center h3,
		.card-center h4 {
			border-bottom: 1px solid #ccc;
			margin: 0!important;
			text-align:center }
			
		.card-center h2.noborder {
			border-bottom: none;
			margin-bottom: 10px!important;
			padding-bottom: 18px!important }
			
		.card-center p.subtitle {
			font-size: 15px;
			margin: 9px 0 24px;
			opacity: .7 }

		.card-center .footer {
			padding:0px 5px 30px;
			text-align: center }
		
		.card-center .footer .btn {
			font-size: 13px;
			margin-bottom: 5px;
			margin-top: 10px }
			
		.card-center .footer .btn-classicLink {
			font-size: 15px;
			margin-bottom: 8px;
			margin-top: -10px }

.card-infocard > .content {
	background-color: #e5e5e5;
	border-bottom: 1px solid #c3112e;
	padding-top: 40px;
	padding-bottom: 12px;
}

.card-infocard > .footer {
	padding-top: 44px;
	padding-bottom: 10px;
}

.card .team-member-image {
	width:40px;
	height:40px;
	overflow:hidden;
	border-radius:50%;
	margin-right:1px }

.card .team-member {
	font-size:12px;
	font-weight:400;
	text-transform:uppercase }

.card-user {
	margin-top: 120px }
	
	@media (min-width:768px) {
		.card-user {
			margin-top: 90px } }
	
.card-user.first {
	margin-top: 90px }

.card-user .team-member {
	text-align:center;
	text-transform:none;
	margin-top:-85px }

.card-user .team-member-image {
	width:140px;
	height:140px;
	border:5px solid #FFF;
	position:relative;
	margin-bottom:15px }

.card-user .title {
	line-height:24px;
	text-align: center }
	
.card-user h3 > small {
	font-size: 80% }

.card-user .content {
	text-align: center }

.card-user .footer {
	padding:0px 5px 30px;
	text-align: center;
	line-height: 2.5em }




.card-table .image {
	height:auto;
	max-height: 175px }
	
.card-table .image img {
	width:auto }

.card-table .content {
	text-align:center;
	padding: 0 }

.card-table .footer {
	padding:0 20px 1px;
	text-align: left;
	font-size: 0.85em;
	text-align: justify }

	@media (max-width:999px) {
		.card-table .footer {
			padding: 15px 15px 1px 15px } }






.card-image,
.card-video {
	background-color:transparent;
	box-shadow: none;
	border-radius:0;
	width: 100%;
	position: relative }

	.card-image .image,
	.card-video .video {
		-webkit-border-radius:4px;
		border-radius:4px;
		height:100%!important }
		
	.card-video .video video {
		border-radius: 4px;
		-webkit-border-radius:4px;
		overflow:hidden }
		
	.corner-top-left, .corner-top-right, .corner-bot-left, .corner-bot-right {
	    width: 10px;
	    height: 10px;
	    position: absolute;
	    background: url(/vid/corners.png) no-repeat;
	    z-index: 1 }
	
	.corner-top-left {
		top: 0;
		left: 0;
		background-position: 0 0 }
		
	.corner-top-right {
		top: 0;
		right: 0;
		background-position: -10px 0 }
		
	.corner-bot-left {
		bottom: 4px;
		left: 0;
		background-position: 0 -10px }
	
	.corner-bot-right {
		bottom: 4px;
		right: 0;
		background-position: -10px -10px }


.card .filter {
	position:absolute;
	z-index:2;
	background-color:rgba(0,0,0,.50);
	top:0;
	left:0;
	width:100%;
	height:100%;
	text-align:center;
	opacity:0;
	filter:alpha(opacity=0) }

.card:hover .filter {
	opacity:1;
	filter:alpha(opacity=100) }

.card .btn-hover {
	opacity:0;
	filter:alpha(opacity=0) }

.card:hover .btn-hover {
	opacity:1;filter:alpha(opacity=100) }

.filter.filter-white {
	background-color:rgba(255,255,255,.91) }
	
.filter.filter-blue {
	background-color:rgba(64,91,132,.75) }

.filter.filter-green {
	background-color:rgba(4,124,10,.69) }

.filter.filter-red {
	background-color:rgba(252,13,0,.69) }




/* PE-Icons
----------------------------------------------------*/


.info {
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: center }

	@media (min-width: 992px) {
		.info {
		    margin-top: 20px;
		    margin-bottom: 45px	} }

.info .icon {
    background: transparent;
    margin-top: 0;
    padding: 8px; }

.icon i {
    color: #7098cc;
    font-size: 70px }
    
.info:hover > .icon i,
.info:focus > .icon i,
.info:active > .icon i {
	color: #c3112e }

.info h5 {
	color: #444;
	font-size: 1.1em;
	margin-top: .8em }

.info p {
	font-size: 1em }




/* CAROUSEL
----------------------------------------------------*/

carousel-inner>.item>a>img,
.carousel-inner>.item>img {
	display:block;
	max-width:100%;
	height:auto }

.carousel {
	position:relative }

.carousel-inner {
	position:relative;
	width:100%;
	overflow:hidden }

.carousel-inner>.item {
	position:relative;
	display:none;
	-webkit-transition:.6s ease-in-out left;
	-o-transition:.6s ease-in-out left;
	transition:.6s ease-in-out left }

.carousel-inner>.item>a>img,
.carousel-inner>.item>img {
	line-height:1 }

.carousel-inner>.active,
.carousel-inner>.next,
.carousel-inner>.prev {
	display:block }

.carousel-inner>.active {
	left:0 }

.carousel-inner>.next,
.carousel-inner>.prev {
	position:absolute;
	top:0;
	width:100% }

.carousel-inner>.next {
	left:100% }

.carousel-inner>.prev {
	left:-100% }

.carousel-inner>.next.left,
.carousel-inner>.prev.right {
	left:0 }
	
.carousel-inner>.active.left {
	left:-100% }

.carousel-inner>.active.right {
	left:100% }

.carousel-indicators {
	position:relative;
	top: 24px;
	left:50%;
	z-index:15;
	width:60%;
	padding-left:0;
	margin-left:-30%;
	text-align:center;
	list-style:none }

.carousel-indicators li {
	display:inline-block;
	width:10px;
	height:10px;
	margin:1px;
	text-indent:-999px;
	cursor:pointer;
	background-color:#000\9;
	background-color:rgba(0,0,0,0);
	border:1px solid #fff;
	border-radius:10px }

.carousel-indicators li.active {
	width:10px;
	height:10px;
	margin:1px;
	background-color:#fff }

@media screen and (min-width:768px){
	.carousel-indicators {
		top:24px } }

.carousel-indicators li {
	margin:1px 5px 1px 5px;
	border-color:#fff }
	
.carousel-indicators li.active {
	margin:1px 5px 1px 5px;
	background-color:#fff }

.carousel-inner>.item>a>img,
.carousel-inner>.item>img {
	display:block;
	margin:0 auto;
	height:auto;
	border-radius:10px }
	
.carousel.fade {
	opacity:1 }
	
.carousel.fade .item {
	transition:opacity ease-in-out .8s;
	left:0!important;
	opacity:0;
	top:0;
	position:absolute;
	width:100%;
	display:block!important;
	z-index:1 }

.carousel.fade .item,
.carousel.fade .item.active {
	-moz-transition:opacity ease-in-out .8s;
	-o-transition:opacity ease-in-out .8s;
	-webkit-transition:opacity ease-in-out .8s }

.carousel.fade .item:first-child {
	top:auto;
	position:relative }

.carousel.fade .item.active {
	opacity:1;
	transition:opacity ease-in-out .8s;
	z-index:2 }

.carousel,
.carousel-inner,
.carousel-indicators {
	margin-bottom:0;
	padding-bottom:0 }




/* Table
--------------------------------------------------*/

table {
	border-spacing: 0;
	border-collapse: collapse;
	border-bottom: 2px solid #ccc;
	font-size: 0.95em }

table.table-elements {
	border-bottom-color: #f3f3f3 }

td,
th {
	padding: 0 }
	
table {
	background-color: transparent }
	
th {
	font-weight: bold!important;
	letter-spacing: 1px }
	
th,
td {
 	text-align: left }
 	
th.text-right,
td.text-right {
 	text-align: right }
 	
.table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px }
	
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
	padding: 8px;
	line-height: 1.42857143;
	vertical-align: top;
	border-top: 0px solid #ddd }
	
.table > thead > tr > th {
	vertical-align: bottom;
	border-bottom: 1px solid #ccc }
	
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
	border-top: 0 }
	
.table > tbody + tbody {
	border-top: 2px solid #ddd }
	
.table .table {
	background-color: #fff }
	
.table-striped > tbody > tr:nth-child(odd) {
	background-color: #f3f3f3 }
	
.table-striped.purple > tbody > tr:nth-child(odd) {
	background-color: #b9abd7 }
	
table col[class*="col-"] {
	position: static;
	display: table-column;
	float: none }
	
table td[class*="col-"],
table th[class*="col-"] {
	position: static;
	display: table-cell;
	float: none }
	
.table-responsive {
	min-height: .01%;
	overflow-x: auto }
	
	@media screen and (max-width: 999px) {
		.table-responsive {
			width: 100%;
			margin-bottom: 15px;
			overflow-y: hidden;
			-ms-overflow-style: -ms-autohiding-scrollbar;
			border: 0px solid #ddd }
		.table-responsive > .table {
			margin-bottom: 0 }
		.table-responsive > .table > thead > tr > th,
		.table-responsive > .table > tbody > tr > th,
		.table-responsive > .table > tfoot > tr > th,
		.table-responsive > .table > thead > tr > td,
		.table-responsive > .table > tbody > tr > td,
		.table-responsive > .table > tfoot > tr > td {
			white-space: nowrap } }

.table>thead>tr>th {
	border-bottom-width:1px }

.table {
	border-bottom-width:0px }

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
	padding:20px 20px;
	vertical-align:middle }

	@media (max-width:999px) {
		.table>tbody>tr>td,
		.table>tbody>tr>th,
		.table>tfoot>tr>td,
		.table>tfoot>tr>th,
		.table>thead>tr>td,
		.table>thead>tr>th {
			padding:15px 15px } }

.table>tbody>tr {
	position:relative }

.table>tbody>tr:hover .td-actions .btn {
	opacity:1;
	filter:alpha(opacity=100) }

@media (max-width:999px) {
	.table-responsive {
		width:100%;
		margin-bottom:0;
		overflow-x:scroll;
		overflow-y:hidden;
		border:0px solid #ddd;
		-ms-overflow-style:-ms-autohiding-scrollbar;
		-webkit-overflow-scrolling:touch } }

tr.bottomborder {
	border-bottom: 1px solid #ccc }
	



/* Modal
----------------------------------------------------*/

.modal-dialog {
  width: 750px }

@media (min-width: 992px) {
.modal-dialog {
  width: 950px } }

.modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width:inherit;
    height:inherit;
    /* To center horizontally */
    margin: 0 auto;
    background-color: #ccc;
	border: none;
	-webkit-box-shadow: none;
	box-shadow: none }
	
.modal-title {
	font-size: 125% }

.modal-header {
	text-align: center }

.modal-footer {
	text-align: center;
	border: none;
	padding: 0 }
	
/* scroll fixes */
.modal-open .modal {
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow-y: scroll }
  
.vertical-alignment-helper {
    display:table;
    height: 100%;
    width: 100% }
    
.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle }




/* WELL
----------------------------------------------------*/

.well {
	background: #ac9fc8;
	color: #fff;
	border: 0;
	padding: 0;
	margin-bottom: 30px }

div.purple-light .well {
	background: #fff;
	color: #444 }
	
.well-white {
	background: #fff;
	color: #444;
	border: 1px solid #ddd }
	
.well.legal {
	margin-top: 40px;
	margin-bottom: 40px }
	
.well a img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px }
	
.well .media {
	padding: 30px }
	
.well .media-body {
	padding-right: 15px }

.well .media-right {
	width: 37%;
	text-align: right }
	
	@media (min-width: 400px) {
		.well .media-right {
			width: 27% } }
	@media (min-width: 768px) {
		.well .media-right {
			width: 14% } }
	@media (min-width: 900px) {
		.well .media-right {
			width: 12% } }
	@media (min-width: 1200px) {
		.well .media-right {
			width: 9% } }

.well i {
	color: #ac9fc8 }
	
.well i.fa-stack-2x {
	color: #fff }

.well h2,
.well h3 {
	padding: 0;
	border: 0;
	margin-top: 0;
	margin-bottom: 5px;
	font-size: 125%;
	color: #fff;
	text-align: left }
	
.well-white h2,
div.purple-light .well h2 {
	color: #444 }
	
.well h2.imprint {
	margin-bottom: 18px }
	
.well p {
	padding: 0;
	margin-bottom: 0;
	font-size: 100%;
	color: #fff }
	
.well p.small {
	font-size: 90%;
	line-height: 1.5em }
	
.well-white p,
div.purple-light .well p {
	color: #444!important }

.well a {
	color: #fff }
	
.well-white a,
div.purple-light .well a {
	color: #444 }
	
.well a:hover h2,
.well a:focus h2,
.well a:active h2,
.well a:hover h3,
.well a:focus h3,
.well a:active h3 {
	color: #fff }

.well:hover i.fa-stack-1x,
.well:focus i.fa-stack-1x,
.well:active i.fa-stack-1x {
	color: #fff }
	
.well:hover i.fa-stack-2x,
.well:focus i.fa-stack-2x,
.well:active i.fa-stack-2x {
	color: #c3112e }



/* PANELS
----------------------------------------------------*/

.panel-group {
	margin-top: 40px }

.panel-group .panel {
	margin-bottom: 30px }

.panel {
	border-radius: 4px }

.panel-body {
	padding: 20px 20px 6px;
	color: #444 }
	
.panel-body p {
	color: #444!important;
	font-size: inherit;
	line-height: 1.7em }

.panel-heading {
	padding: 0px }

.panel-default > .panel-heading {
	color: #c3112e;
	border-color: #ddd;
	background: #fff }
	
.panel-heading a {
	padding: 20px;
	display: inline-block;
	width: 100%;
	position: relative;
	text-decoration: none }
	
.panel-heading a small {
	display: inline-block;
	margin-bottom: 8px;
	font-size: 85% }

.panel-heading a .panel-title-left {
	display: inline-block;
	max-width: 85% }

.panel-heading a:after {
	font-family: "fontello";
	content: "\e800";
	position: absolute;
	right: 20px;
	font-size: 20px;
	font-weight: 300;
	top: 50%;
	line-height: 1;
	margin-top: -10px }

.panel-heading a.collapsed:after {
	content: "\e801" }

.panel-default > .panel-heading.active {
	background: #c3112e!important;
	color: #fff!important }
        
.panel-default > .panel-heading.active a {
    background: inherit;
    color: #fff!important }
    
.panel-default > .panel-heading.active a small {
    background: inherit;
    color: #fff!important }

.panel-title {
	line-height: 1.5em!important }
	
h2.panel-title {
	color: #c3112e;
	border-bottom: none;
	margin: 0 auto;
	padding: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: 1.1em;
	text-align: left }
	
.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
	padding-left: 20px!important;
	padding-right: 20px!important;
	font-size: 95%; }

.panel-footer {
	padding: 20px;
	color: #444;
	background: #fff;
	border-top: 1px solid #ddd!important;
	font-size: smaller }




/* FORMS
----------------------------------------------------*/

form {
	margin: 0 0 24px }

fieldset {
	padding: 0;
	margin: 0;
	border: 0 }

label,
input,
button,
select,
textarea {
	font-size: 17px;
	font-weight: 300;
	line-height: 35px }

input,
button,
select,
textarea {
	font-family: 'Lato',"Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif }

label {
	display: block;
	margin-bottom: 0;
	margin-top: 2px;
	color: #222 }

select,
textarea,
input[type="text"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="checkbox"] {
	display: inline-block;
	height: 45px;
	padding: 6px 12px;
	font-size: 17px;
	line-height: 35px;
	color: #555555;
	vertical-align: middle }

input,
textarea,
.uneditable-input {
	width: 100%  }

textarea {
	height: auto }

textarea,
input[type="text"],
input[type="datetime"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="checkbox"] {
	background-color: white;
	border: 1px solid #bcb7b0;
	border-radius: 4px; }

textarea:focus,
input[type="text"]:focus,
input[type="datetime"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="checkbox"]:focus {
	border-color: #89ba16;
    outline: 0;
    outline: thin dotted \9;
    /* IE6-9 */ }

.form-control,
select {
  display: block;
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  font-size: 17px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #bcb7b0;
  border-radius: 4px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
.form-control:focus,
select:focus {
  border-color: #89ba16;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control::-moz-placeholder,
select::-moz-placeholder {
  color: #555555;
  opacity: 1;
  font-weight: 300;
  font-size: 100%
}
.form-control:-ms-input-placeholder,
select:-ms-input-placeholder {
  color: #555555;
  font-weight: 300;
  font-size: 100%
}
.form-control::-webkit-input-placeholder,
select::-webkit-input-placeholder {
  color: #555555;
  font-weight: 300;
  font-size: 100%
}
.form-control::-ms-expand,
select::-ms-expand {
  border: 0;
  background-color: transparent;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}
textarea.form-control,
textarea.select {
  height: auto;
}

.form-group {
	margin-bottom: 24px }

.form-group-privacyHint {
	margin-bottom: 20px }

.form-group .errors {
	margin-top: -12px;
	margin-bottom: 12px; }
	.form-group .errors span {
		color: white;
		display: inline-block;
		padding: 0 12px;
		background-color: #cc0000 }

ul.errors {
	padding-left: 15px }
	




.checkbox {
  padding-left: 20px;
  line-height: 1.6em; }
  .checkbox label {
    display: inline-block;
    position: relative;
    padding-left: 8px;
    line-height: 1.6em;
    font-weight: 300;
    font-size: 85%;  }
    @media (min-width: 520px){
	    .checkbox label {
		    text-align: justify; } }
    .checkbox label::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 17px;
      height: 17px;
      left: 0;
      margin-top: 4px;
      margin-left: -20px;
      border: 1px solid #cccccc;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out; }
    .checkbox label::after {
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 0;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #555555; }
  .checkbox input[type="checkbox"] {
    opacity: 0; }
    .checkbox input[type="checkbox"]:focus + label::before {
      outline: thin dotted;
      outline: 0px auto -webkit-focus-ring-color;
      outline-offset: -2px; }
    .checkbox input[type="checkbox"]:checked + label::after {
      font-family: 'Fontello';
      content: "\e80b"; }
  .checkbox.checkbox-inline {
    margin-top: 0; }

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca; }
.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before {
  background-color: #d9534f;
  border-color: #d9534f; }
.checkbox-danger input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before {
  background-color: #5bc0de;
  border-color: #5bc0de; }
.checkbox-info input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before {
  background-color: #f0ad4e;
  border-color: #f0ad4e; }
.checkbox-warning input[type="checkbox"]:checked + label::after {
  color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before {
  background-color: #89ba16;
  border-color: #89ba16; }
.checkbox-success input[type="checkbox"]:checked + label::after {
  color: #fff; }




.has-error input,
.has-error select,
.has-error textarea {
	border-color: #c3112e!important; }

.has-error label {
	color: #c3112e!important; }




/* MAPS
----------------------------------------------------*/

#map-container {
	height: 450px;
	margin-top: 0;
	border-top: 1px solid #dedede;
	border-bottom: 1px solid #dedede;
	overflow: hidden }
	@media (min-width: 800px) {
    	#map-container {
    		margin-top: 0px } }
    		
    .info_content {
	    text-align: center;
	    margin-left: 10px;
	    margin-right: 10px }
	    
	.info_content img {
		margin: 12px 0 12px 0 }
		
	.info_content svg {
		margin: 12px 0 12px 0 }

.gm-style-iw-c {
	border-radius: 4px!important;
}
    
.gm-style-iw-d {
	padding: 15px;
	margin: 0 }




/* FOOTER
----------------------------------------------------*/

footer {
	background: #444;
	border-top: none;
	margin-top: 0;
	padding: 0;
	text-align: center;
	font-weight: 300;
	font-size: .90em;
	clear: both }
	@media (min-width: 768px) {
    	footer {
			text-align: left } }

footer .wrapper {
    *zoom: 1; 
    font-size: 85%;
    color: #FFF }

footer .wrapper:before,
footer .wrapper:after {
    display: table;
    content: "";
    line-height: 0 }

footer .wrapper:after {
      clear: both }

footer h1 {
	font-weight: 700;
	color: #fff;
	padding: 0;
	line-height: 2em;
	font-size: 24px;
	font-weight: 300;
	margin: 5px 0 0 0;
	text-align: left }

footer p {
    color: #FFF;
    margin: 0;
    line-height: 2em;
    font-size: 100% }
                
footer ul.ulright {
	list-style: none;
	padding: 0;
	line-height: 2.6em;
	margin-top: 12px }
	   
	@media (min-width: 768px) {
    	footer ul.ulright {
	    	line-height: 2.6em;
        	float: right }
		footer .xo-concept {
        	margin: 0;
			float: right;
			line-height: 2em;
			letter-spacing: .1px } }
        
footer a {
    color: #fff!important }
    
footer a.active {
  	color: #fff!important }
  	
footer a:hover,
footer a:focus,
footer a:active {
    color: #bcb7b0!important;
    text-decoration: none }
    
footer a.active:hover,
footer a.active:focus,
footer a.active:active {
    text-decoration: none;
    color: #bcb7b0!important }

@media (max-width: 767px) {  	
	footer span.xsmall {
  		display: none } }

.accent {
	border-bottom: 1px solid #ddd; 
	width: 100%; 
	height: 1px;
	margin-top: 12px;
	margin-bottom: 24px; }

.footer-brand {
	margin-top: 22px
}

svg.footer-brand {
	height: 45px;
	width: 195px;
 	display: none }
 	
 	@media (min-width: 768px) {
	 	svg.footer-brand {
		 	display: inline-block;
		 	margin-bottom: 0px; } }
	


/* SOCIAL
----------------------------------------------------*/

.social {
    padding: 0;
    margin: 18px 0 18px;
    list-style: none;
    position: relative;
    z-index: 100 }
    
    @media (min-width: 768px) {
	.social {
		margin-top: 2px;
		margin-bottom: 0 } }

.social li {
    display: inline-block;
    margin-right: 20px }
    
    @media (min-width: 768px) {
	    .social li {
		    margin-right: 20px } }
    
.social li.last {
	margin-right: 0 }

.social li a {
    display: table;
    position: relative }

.social li a i {
	color: #ffffff;
	border: 1px solid #fff; 
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    width: 60px;
    height: 48px;
    font-size: 20px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out }
    
    @media (min-device-width: 768px) {  	
		.social li a i {
			width: 60px;
			height: 48px;
			font-size: 20px } }
    
    @media (min-device-width: 1025px) {  	
		.social li a i {
			width: 66px;
			height: 48px;
			font-size: 22px } }

.social li a i:after {
    content: '';
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out }

.social li a:hover i {
    color: #c3112e }

.social li a:hover i:after {
    height: 48px }
    
    @media (min-device-width: 768px) {
	    .social li a:hover i:after {
			height: 48px } }
    
    @media (min-device-width: 1025px) {
	    .social li a:hover i:after {
			height: 48px } }

.social a .icon-facebook:after {
    background: #fff }

.social a .icon-google-plus:after {
    background: #fff }

.social a .icon-twitter:after {
    background: #fff }

.social a .icon-pinterest:after {
    background: #fff }

.social a .icon-behance:after {
    background: #fff }
    
.social a .icon-dribbble:after {
    background: #fff }

.social a .icon-instagram:after {
    background: #fff }
    
.social a .icon-envelope:after {
    background:  #fff }

.social a .icon-phone:after {
    background:  #fff }

.social a .icon-comment-o:after {
    background:  #fff }  

@media (min-device-width: 1025px) {
	li.mobile-only {
  		display: none } }

@media (max-device-width : 1024px) {
	li.mobile-only {
  		display: inline-block } }

@media (min-device-width: 1025px) {
	li.desktop-only {
  		display: inline-block } }

@media (max-device-width : 1024px) {
	li.desktop-only {
  		display: none } }
  		
@media (min-width: 568px) {
	h1.mobile-only,
	h2.mobile-only {
  		display: none } }

@media (max-width : 567px) {
	h1.mobile-only,
	h2.mobile-only {
  		display: block } }

@media (min-width: 568px) {
	h1.desktop-only,
	h2.desktop-only {
  		display: block } }

@media (max-width : 567px) {
	h1.desktop-only,
	h2.desktop-only {
  		display: none } }

